import { cancelGoldTrasansaction, goldRequest, goldTxnRequest } from '@api/useQueryCalls';
import GoldBanner from '@atoms/GoldBanner';
import RemoteImage from '@atoms/RemoteImage';
import config from '@config';
import LoginInfoSheet from '@molecules/LoginInfoSheet';
import Promocode from '@molecules/Promocode';
import RequestTransactionSheet from '@molecules/RequestTransactionSheet';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import { KTP_INFO_MODAL } from '@root/constants';
import { logRequestBP } from '@screens/GGBillPayments/GGNominals/analyticsNominals';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { SVG_ICONS } from '@utils/SvgIcons';
import { returnBottomSheetContent, serverError } from '@utils/utils';
import { Button, ScrollView, Text, useToast } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Dimensions } from 'react-native';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import { logCloseButton, logContinueButton, logSumbitHandler } from './analyticsGoldLanding';
import {
  shouldRenderBenifitsDisplay,
  shouldRenderBonusText,
  shouldRenderHadiah,
  shouldRenderSponsorors,
  shouldRenderTermsBand,
} from './goldUtils';
import styles from './style';
import productConfig from '../../../../product';

const { width: SCREEN_WIDTH } = Dimensions.get('window');
const errorCodeKTP = 'GG-USER-05';
export function showErrorOnAPI(isError, goldReqError, toast, t, navigation, setInitialData, setInfoModal) {
  if (isError && goldReqError) {
    if (goldReqError.length > 0 && goldReqError[0] === errorCodeKTP) {
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Treasury.gold_id_bottom_sheet.name, {});
      return setInfoModal({
        visible: true,
        data: returnBottomSheetContent(KTP_INFO_MODAL[errorCodeKTP], KTP_INFO_MODAL[errorCodeKTP]),
        errorCode: KTP_INFO_MODAL[errorCodeKTP],
      });
    }

    if (goldReqError.length > 0 && goldReqError[0] === 'NO_KTP_FOUND') {
      AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Treasury.gold_id_bottom_sheet.name, {});
      return setInfoModal({
        visible: true,
        data: returnBottomSheetContent(KTP_INFO_MODAL[errorCodeKTP], KTP_INFO_MODAL[errorCodeKTP]),
        errorCode: KTP_INFO_MODAL[errorCodeKTP],
      });
    }

    serverError(goldReqError, toast, t, navigation, setInitialData);
  }
}
export const renderItem = ({ item }) => <RemoteImage uri={item.uri} key={item.uri} isFullSize contentFit="strech" />;

export const onPressPrimaryModalButton = (setInfoModal, infoModal, navigation) => {
  logContinueButton();
  setInfoModal({
    ...infoModal,
    visible: false,
  });

  navigation.navigate('GoldKTP');
};

export const onCloseModal = (setInfoModal, infoModal) => {
  logCloseButton();
  setInfoModal({
    ...infoModal,
    visible: false,
  });
};

export const onConfirmErorHandler = (cancelGoldTxn, setPromocode, goldTxnData) => {
  cancelGoldTxn({ txnID: goldTxnData?.id });
  setPromocode('');
};

export function shouldCloseModalHandler(openRTS, cancelGoldTxn, goldTxnData, setPromocode, setTreasury, setOpenRTS) {
  return (flag, isPressed) => {
    if (openRTS && isPressed) {
      cancelGoldTxn({ txnID: goldTxnData.id });
      setPromocode(null);
      setTreasury(null);
    }
    setOpenRTS(flag);
  };
}

function GGGoldLanding() {
  const {
    employeeId,
    setInitialData,
    treasuryPayload,
    setTreasury,
    setLoading,
    setPromocode,
    promocode,
    meAmplitudeContext,
  } = getStore();

  const navigation = useNavigation();
  const DATA = [
    { uri: `${config.MEDIA_STORAGE}gold/banner_1.png` },
    { uri: `${config.MEDIA_STORAGE}gold/banner_2.png` },
    { uri: `${config.MEDIA_STORAGE}gold/banner_3.png` },
    { uri: `${config.MEDIA_STORAGE}gold/banner_4.png` },
  ];

  const txnID = treasuryPayload?.invoice_no ?? null;

  const [openRTS, setOpenRTS] = useState(false);

  const {
    isLoading: isGoldRequestLoading,
    mutate: goldRequestCall,
    isError: isGoldReqError,
    error: goldReqError,
  } = goldRequest({ employeeId }, data => {
    navigation.navigate('GoldWebView', { webUrl: data?.webView, entrySource: 'Landing' });
  });

  const isFocused = useIsFocused();
  const route = useRoute();

  const { mutate: cancelGoldTxn } = cancelGoldTrasansaction({ employeeId }, () => {
    setPromocode('');
    setTreasury(null);
  });

  const {
    isLoading: isGoldTxnLoading,
    mutate: goldTxnReqCall,
    isError: isGoldTxnReqError,
    error: goldTxnError,
    data: goldTxnData,
  } = goldTxnRequest({ txnID }, () => {
    setOpenRTS(true);
    setTreasury(null);
  });

  const [infoModal, setInfoModal] = useState({
    visible: false,
    data: returnBottomSheetContent(KTP_INFO_MODAL[errorCodeKTP], KTP_INFO_MODAL[errorCodeKTP]),
    errorCode: KTP_INFO_MODAL[errorCodeKTP],
  });

  const reasonsCarousel = useRef();
  const [activeIndex, setActiveIndex] = useState(0);

  const setCarouselActiveIndex = index => {
    setActiveIndex(index);
  };

  const toast = useToast();
  const { t } = useTranslation('common');

  useEffect(() => {
    showErrorOnAPI(isGoldReqError, goldReqError, toast, t, navigation, setInitialData, setInfoModal);
  }, [isGoldReqError, goldReqError]);

  useEffect(() => {
    showErrorOnAPI(isGoldTxnReqError, goldTxnError, toast, t, navigation, setInitialData, setInfoModal);
  }, [isGoldTxnReqError, goldTxnError]);

  useEffect(() => {
    setLoading(isGoldTxnLoading);
  }, [isGoldTxnLoading]);

  function prefetchImages(urls) {
    const promises = urls.map(url => Image.prefetch(url.uri));
    return Promise.all(promises);
  }

  useEffect(() => {
    prefetchImages(DATA);
  }, []);

  useEffect(() => {
    if (txnID) {
      logRequestBP(
        null,
        productConfig,
        { code: null },
        route?.params?.source,
        null,
        meAmplitudeContext,
        'salary_account', // hard coding this, since gold is only eligible for EWA
      );
      goldTxnReqCall({ txnID });
    }
  }, [txnID]);

  const onGoldTermsHandler = () => navigation.navigate('GGGoldTerms');

  const onSubmitHandler = src => {
    logSumbitHandler(src);
    goldRequestCall({ employeeId });
  };

  const onModalCloseHandler = shouldCloseModalHandler(
    openRTS,
    cancelGoldTxn,
    goldTxnData,
    setPromocode,
    setTreasury,
    setOpenRTS,
  );

  useEffect(() => {
    if (isFocused) {
      const e = AMPLITUDE_CONSTANTS.Treasury.financial_page;
      AmplitudeHelper.logEvent(e.name, {
        [e.attributes.source]: route?.params?.source ?? 'nav_bar',
        [e.attributes.transaction_category_id]: 'gd-01',
      });
    }
  }, [isFocused, route]);

  return (
    <ScrollView style={styles.containerStyle} contentContainerStyle={styles.contentContainerStyle}>
      <Carousel
        ref={reasonsCarousel}
        itemWidth={SCREEN_WIDTH}
        sliderWidth={SCREEN_WIDTH}
        data={DATA}
        testID="carousel-gold"
        renderItem={renderItem}
        inactiveSlideOpacity={1}
        inactiveSlideScale={1}
        onSnapToItem={setCarouselActiveIndex}
        enableSnap
        contentContainerCustomStyle={styles.carouselContentStyle}
      />

      <Pagination
        dotsLength={DATA.length}
        activeDotIndex={activeIndex}
        containerStyle={styles.paginationContainer}
        dotStyle={styles.dotStyle}
        inactiveDotStyle={styles.inactiveStyle}
        inactiveDotScale={1}
        animatedDuration={0}
        carouselRef={reasonsCarousel}
      />
      <Promocode />

      <Button
        isLoading={isGoldRequestLoading}
        isDisabled={isGoldRequestLoading}
        textAlign="center"
        testID="emasSayaBtn"
        onPress={() => onSubmitHandler('promo_banner_button')}
        style={styles.buttonStyle}
        leftIcon={SVG_ICONS.treasury.buttonGold}>
        <Text variant="md-bold" color={colors.neutral.cotton}>
          {t('treasury.mainButtontitle')}
        </Text>
      </Button>

      {shouldRenderBenifitsDisplay(t, SVG_ICONS)}
      {shouldRenderTermsBand(onGoldTermsHandler, t)}
      {shouldRenderHadiah(t)}
      <GoldBanner
        content={t('treasury.bannerTitle')}
        buttonTitle={t('treasury.bannerButton')}
        onPressHandler={() => onSubmitHandler('financial_page_gold_button')}
        bannerContainerStyle={styles.bannerContainerStyle}
        contentAdjStyle={styles.contentAdjStyle}
        contentTextStyle={styles.contentTextStyle}
        buttonStyle={styles.beliButtonStyle}
        buttonTextStyle={styles.beliTextStyle}
        icon={SVG_ICONS.coin6}
        loading={isGoldRequestLoading}
        show
      />
      {shouldRenderBonusText(t)}
      {shouldRenderSponsorors(t, SVG_ICONS)}

      <LoginInfoSheet
        onPressPrimary={() => onPressPrimaryModalButton(setInfoModal, infoModal, navigation)}
        loginInfo={infoModal}
        onClose={() => onCloseModal(setInfoModal, infoModal)}
      />
      <RequestTransactionSheet
        openRTS={openRTS}
        promocode={promocode}
        setOpenRTS={onModalCloseHandler}
        productConfig={productConfig.GG_01}
        bpReqData={goldTxnData}
        productApiData={{
          screenName: productConfig.GG_01.productName,
        }}
        isGold
        errorHandler={() => onConfirmErorHandler(cancelGoldTxn, setPromocode, goldTxnData)}
      />
    </ScrollView>
  );
}

export default GGGoldLanding;
