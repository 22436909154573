import { Text } from 'native-base';
import React from 'react';
import { View } from 'react-native';
import styles from './style';

export function shouldRenderBenifitsDisplay(t, SVG_ICONS) {
  const benifitsDisplay = [
    { icon: SVG_ICONS.treasury.moneyFilled, displayText: t('treasury.benifitsDisplayOne') },
    { icon: SVG_ICONS.treasury.lock, displayText: t('treasury.benifitsDisplayTwo') },
    { icon: SVG_ICONS.treasury.chartLine, displayText: t('treasury.benifitsDisplayThree') },
  ];

  return (
    <View style={styles.benifitContainer}>
      {benifitsDisplay.map((e, i) => (
        <View key={`benifitsIcon${e.displayText}`} style={styles.benifitSubContainer}>
          <View testID={`benifitsIcon${i}`} style={styles.benifitIconContainer}>
            {e.icon}
          </View>
          <Text variant="xsm-normal" style={styles.benifitTextStyle}>
            {e.displayText}
          </Text>
        </View>
      ))}
    </View>
  );
}

export function shouldRenderTermsBand(onGoldTermsHandler, t) {
  return (
    <View style={styles.termsContainerStyle}>
      <Text variant="sm-normal" style={styles.termsLabelStyle}>
        {t('treasury.termsBandOne')}{' '}
        <Text testID="navigation-terms" variant="sm-normal" onPress={onGoldTermsHandler} style={styles.termsLinkStyle}>
          {t('treasury.termsBandTwo')}
        </Text>
      </Text>
    </View>
  );
}

export function shouldRenderHadiah(t) {
  return (
    <View style={styles.stickStyle}>
      <Text variant="lg-bold" style={styles.stickTextStyle}>
        🎁 {t('treasury.hadia')}
      </Text>
    </View>
  );
}

export function shouldRenderBonusText(t) {
  return (
    <Text variant="xsm-normal" style={styles.bonusTextStyle}>
      {t('treasury.hadiatwo')}
    </Text>
  );
}

export function shouldRenderSponsorors(t, SVG_ICONS) {
  return (
    <View style={styles.sponsorContainer}>
      <View style={styles.subLeftContainer}>
        <Text variant="xsm-normal" style={styles.sponsorTextStyle}>
          {t('treasury.sponsorOne')}
        </Text>
        {SVG_ICONS.treasury.treasuryLogo}
      </View>
      <View style={styles.subLeftContainer}>
        <Text variant="xsm-normal" style={styles.sponsorTextStyle}>
          {t('treasury.sponsortwo')}
        </Text>
        {SVG_ICONS.treasury.bappebti}
      </View>
    </View>
  );
}
