import { GET_USER_PROPERTIES } from '@api/queries/query';
import { useLazyQuery } from '@apollo/client';
import SkeletonComponent from '@atoms/SkeletonComponent';
import navigationConstants from '@navigation/navigationConstants';
import { onGetUserPropertiesCallBack } from '@organisms/ProfileTab/profileUtils';
import { useNavigation } from '@react-navigation/native';
import { getStore } from '@store/storeUtils';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import logLihatPress from './analyticsProfileHaeder';
import ProfileHeaderComponent from './ProfileHeaderComponent';

function ProfileHeader({ isPICReferral }) {
  const {
    // setLoading,
    setEmployeeProfile,
    employeeProfile,
  } = getStore();

  const navigation = useNavigation();

  const isPIC = employeeProfile?.influencerRole && employeeProfile?.influencerRole === 'PIC';

  const [getUserProperties, { loading }] = useLazyQuery(GET_USER_PROPERTIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: onGetUserPropertiesCallBack(setEmployeeProfile),
  });
  // useEffect(() => {
  //   setLoading(loading);
  // }, [loading]);

  useEffect(() => {
    getUserProperties();
  }, []);

  const onLihatPressHandler = () => {
    logLihatPress(isPIC);

    navigation.navigate(navigationConstants.USER_PROFILE);
  };
  if (loading) {
    return <SkeletonComponent />;
  }
  return (
    <ProfileHeaderComponent
      profileData={employeeProfile}
      onLihatPressHandler={onLihatPressHandler}
      isPICReferral={isPICReferral}
    />
  );
}

ProfileHeader.propTypes = {
  isPICReferral: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
ProfileHeader.defaultProps = {
  isPICReferral: false,
};
export default ProfileHeader;
