import Info from '@expo/vector-icons/MaterialIcons';
import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { View, Pressable } from 'react-native';
import departmentStyles from './style';

const DepartmentPicker = ({ title, valueHolder, isSelected, onPress, show }) => {
  if (show) {
    return (
      <Pressable onPress={onPress} style={departmentStyles.container} testID={`picker-container${title}`}>
        <Text variant="sm-normal" style={departmentStyles.label}>
          {title}
        </Text>
        <View style={departmentStyles.pickerContainer}>
          <Text
            variant="sm-normal"
            style={[departmentStyles.placeholder, isSelected && departmentStyles.selectedstyle]}
            testID="value-holder">
            {valueHolder}
          </Text>
          <View testID="arrow-icon">
            <Info name="keyboard-arrow-right" color={colors.neutral.charcoal} size={scale(24)} />
          </View>
        </View>
      </Pressable>
    );
  }

  return null;
};

DepartmentPicker.propTypes = {
  title: PropTypes.string.isRequired,
  valueHolder: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default DepartmentPicker;
