import { shouldShowReasonsModal } from '@molecules/WithdrawButton';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { View, Text, Button } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

interface WithdrawalReasonProps {
  reason?: string;
  setShowReasonSelector?: (value: boolean) => void;
  withdrawAmount?: number;
  showReasonSelector?: boolean;
  withdrawalReason?: {
    id: number,
  };
  setWithdrawalReason?: (reason: { id: number }) => void;
  selectedAccount?: {
    amplitudeAccountName?: string,
  };
  source?: string;
  fromTransfer?: boolean;
  transaction_category_id?: string;
}

const WithdrawalReason: React.FC<WithdrawalReasonProps> = ({
  reason = '',
  setShowReasonSelector = () => {},
  withdrawAmount = 0,
  showReasonSelector = false,
  withdrawalReason = { id: 0 },
  setWithdrawalReason = () => {},
  selectedAccount = { amplitudeAccountName: '' },
  source = '',
  fromTransfer = false,
  transaction_category_id = '',
}) => {
  const { t } = useTranslation('common');
  const { customReasonText, validatedReferralCode } = getStore();
  const { amplitudeAccountName } = selectedAccount;

  const btnPressGanti = () => {
    const e = AMPLITUDE_CONSTANTS.Withdrawal.userChangedReason;
    AmplitudeHelper.logEvent(e.name, {
      [e.attributes.source]: source,
      [e.attributes.referral_code]: validatedReferralCode,
      [e.attributes.dynamic_account_name]: amplitudeAccountName,
    });
    setShowReasonSelector(true);
  };

  return (
    <View style={styles.reasonsContainer}>
      <View flex={1} style={appStyles.paddingVertical(12)}>
        <Text testID="lblReasonHead" variant="sm-normal" style={styles.reasonHeader}>
          {t('reasonsModal.reasonForWithdrawal')}
        </Text>
        <Text testID="lblReason" variant="sm-bold" style={styles.reasonsSelected}>
          {reason}
        </Text>
        {customReasonText ? (
          <Text testID="lblCustomReason" variant="sm-normal" style={appStyles.marginLeft(16)}>
            ({customReasonText})
          </Text>
        ) : null}
      </View>
      <Button size="md" testID="btnWithdraw" variant="ghost" style={styles.buttonStyle} onPress={btnPressGanti}>
        <Text color={colors.primary.carnation} variant="md-bold">
          {t('bp.phoneTransfer')}
        </Text>
      </Button>
      {shouldShowReasonsModal(
        showReasonSelector,
        setShowReasonSelector,
        null,
        withdrawAmount,
        null,
        withdrawalReason,
        setWithdrawalReason,
        selectedAccount,
        fromTransfer,
        source,
        transaction_category_id,
      )}
    </View>
  );
};

export default WithdrawalReason;
