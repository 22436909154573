import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Platform, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  appliedInput: {
    backgroundColor: colors.disabled.smoke,
  },
  button: {
    borderRadius: scale(8),
    paddingHorizontal: scale(14),
    paddingVertical: scale(5.5),
  },
  buttonApplied: {
    backgroundColor: colors.variants.softClover,
  },
  buttonNotApplied: {
    backgroundColor: colors.neutral.cotton,
    borderColor: colors.neutral.darkMist,
    borderWidth: 0.5,
  },
  codeReferralText: {
    color: colors.neutral.darkMist,
    marginEnd: 5,
  },

  commonInputStyle: {
    borderRadius: scale(8),
    borderWidth: 0.5,
    color: colors.neutral.darkMist,
    flex: 1,
    fontSize: scale(14),
    marginEnd: scale(10),
    marginTop: 0,
    paddingHorizontal: scale(16),
    paddingVertical: scale(8),
    ...(Platform.OS === 'web' && { outlineStyle: 'none' }), // ✅ Only applies on web
  },
  container: {
    marginBottom: -10,
    marginHorizontal: scale(10),
    marginTop: scale(20),
  },
  horizontalView: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: scale(16),
    marginHorizontal: scale(16),
    marginTop: scale(6),
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    marginHorizontal: scale(16),
  },
});

export default styles;
