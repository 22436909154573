import colors from '@theme/colors';
import { isArray } from 'lodash';
import React, { useState } from 'react';
import { TextInput, TextInputProps, StyleProp, ViewStyle, TextStyle } from 'react-native';
import styles from './styles';

interface CommonTextInputProps {
  style?: StyleProp<ViewStyle>;
  customBorderStyle?: StyleProp<ViewStyle>;
  amplitudeCallBackOnBlur?: (e: any) => void;
  amplitudeCallBackOnFocus?: (e: any) => void;
  testID?: string;
  editable?: boolean;
  placeholder?: string;
  placeholderTextColor?: string;
  value?: string;
  onChangeText?: (text: string) => void;
  keyboardType?: TextInputProps['keyboardType'];
  multiline?: boolean;
  variant?: TextStyle; // ✅ FIXED: Corrected type for variant
}

const getStylesFromProps = (style: StyleProp<ViewStyle>, borderColor: string): StyleProp<ViewStyle> => {
  if (isArray(style)) {
    return [...style, { borderColor }];
  }
  return { ...style, borderColor };
};

const CommonTextInput: React.FC<CommonTextInputProps> = ({
  style = {},
  customBorderStyle = {},
  amplitudeCallBackOnBlur = () => {},
  amplitudeCallBackOnFocus = () => {},
  testID = 'txtInputCommon',
  editable = true,
  placeholder = '',
  placeholderTextColor = colors.neutral.darkMist,
  value = '',
  onChangeText,
  keyboardType = 'default',
  multiline = false,
  variant,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const customBorderColor = isFocused ? colors.secondary.orchid : colors.neutral.darkMist;

  const handleFocus = (e: any) => {
    setIsFocused(true);
    amplitudeCallBackOnFocus(e);
  };

  const handleBlur = (e: any) => {
    setIsFocused(false);
    amplitudeCallBackOnBlur(e);
  };

  return (
    <TextInput
      testID={testID}
      blurOnSubmit
      onFocus={handleFocus}
      onBlur={handleBlur}
      editable={editable}
      placeholder={placeholder}
      placeholderTextColor={placeholderTextColor}
      value={value}
      onChangeText={onChangeText}
      keyboardType={keyboardType}
      multiline={multiline}
      style={[
        styles.inputStyles,
        getStylesFromProps(style, customBorderColor),
        customBorderStyle,
        variant, // ✅ FIXED: Ensure `variant` is applied correctly
      ]}
    />
  );
};

export default CommonTextInput;
