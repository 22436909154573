import colors from '@theme/colors';
import { scale } from '@utils/normalize';
import { Platform, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  buttonViewStyle: {
    backgroundColor: colors.neutral.cotton,
    bottom: 0,
    paddingTop: scale(16),
    position: 'absolute',
    width: '100%',
  },
  customTextStyle: { flex: 1, paddingLeft: scale(8) },
  infoContainerStyle: { marginHorizontal: scale(16), marginTop: scale(16), paddingLeft: scale(12) },
  scrollViewContainerStyle: { flexGrow: 1, paddingBottom: scale(100) },
  textAreaStyles: {
    alignSelf: 'stretch',
    borderColor: colors.neutral.darkMist,
    borderRadius: scale(8),
    borderWidth: 1,
    color: colors.neutral.charcoal,
    marginHorizontal: scale(16),
    minHeight: scale(92),
    ...(Platform.OS === 'web' && { outlineStyle: 'none' }), // ✅ Only applies on web
    paddingHorizontal: scale(16),
    paddingVertical: 13,
    textAlign: 'auto',
  },
  titleStyle: { marginBottom: scale(8), marginLeft: scale(16), marginTop: scale(16) },
});

export default styles;
