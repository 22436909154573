import { AntDesign } from '@expo/vector-icons';
import colors from '@theme/colors';
import { View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { TextInput } from 'react-native';
import styles from './styles';

const SearchListHeaderComponent = ({ searchData, setFilteredData, searchKey, searchPlaceholder, logSearchDone }) => {
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (searchText.length > 0) {
      const filteredData = searchData.filter(data => data[searchKey].toUpperCase().includes(searchText.toUpperCase()));
      if (filteredData.length > 0) {
        setFilteredData(filteredData);
      }
    } else {
      setFilteredData(searchData);
    }
  }, [searchText]);

  const onChangeText = txtChange => {
    setSearchText(txtChange);
  };

  return (
    <View backgroundColor={colors.secondary.orchid}>
      <View flexDirection="row" style={styles.searchContainer}>
        <TextInput
          testID="regionInput"
          placeholderTextColor={colors.neutral.darkMist}
          style={styles.txtInputSearch}
          placeholder={searchPlaceholder}
          onChangeText={onChangeText}
          blurOnSubmit
          onBlur={logSearchDone}
        />
        <AntDesign name="search1" size={24} color={colors.neutral.darkMist} style={styles.searchIcon} />
      </View>
    </View>
  );
};

SearchListHeaderComponent.propTypes = {
  searchData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      bankName: PropTypes.string,
      bankBranch: PropTypes.string,
      bankCode: PropTypes.string,
    }),
  ),
  setFilteredData: PropTypes.func,
  searchKey: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  logSearchDone: PropTypes.func,
};
SearchListHeaderComponent.defaultProps = {
  searchData: [{ id: 1, name: '' }],
  setFilteredData: () => {},
  searchKey: '',
  searchPlaceholder: '',
  logSearchDone: () => {},
};

export default SearchListHeaderComponent;
