import { fetchTransactionHistory } from '@api/useQueryCalls';
import { generalEmptyState } from '@assets/png/imagesPng';
import TransactionItem from '@atoms/TransactionItem';
import navigationConstants from '@navigation/navigationConstants';
import { useNavigation } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { showErrorOnAPI } from '@screens/GGBillPayments/BPUtils';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import { isNotPoints } from '@utils/utils';
import { useToast, Text } from 'native-base';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Image, View } from 'react-native';
import logTxnClick from './analtyicsTxnList';
import styles from './styles';
import shouldShowFlatListOrLoader from './transactionListUtilties';
import Transactiondetails from '../../../Transactiondetails';

export const onPressHandler = (item, selectedTxnAccount, navigation) => {
  logTxnClick(item);
  let detailsConfig = null;
  if (item?.transactionCategoryId?.startsWith('BP')) detailsConfig = Transactiondetails[item.transactionCategoryId];
  if (item?.transactionCategoryId?.startsWith('WD'))
    detailsConfig = Transactiondetails[`WITHDRAWAL_${selectedTxnAccount?.accountTypeId}`];
  if (item?.transactionCategoryId?.startsWith('GG')) {
    if (item?.transactionReasonId === 47) detailsConfig = Transactiondetails[`LOAN-01`];
    else detailsConfig = Transactiondetails[`GG_${selectedTxnAccount?.accountTypeId}_${item.position}`];
  }
  if (item?.transactionCategoryId?.startsWith('GD')) detailsConfig = Transactiondetails[item.transactionCategoryId];
  if (detailsConfig)
    navigation.navigate(navigationConstants.GG_EDIT_TRANSACTION, {
      detailsConfig,
      detailsData: item,
      accountTypeId: selectedTxnAccount?.accountTypeId,
    });
};

export function renderItemHandler(IS_NOT_POINTS, selectedTxnAccount, navigation) {
  // eslint-disable-next-line react/prop-types
  return ({ item }) => (
    <TransactionItem
      IS_NOT_POINTS={IS_NOT_POINTS}
      selectedTxnAccount={selectedTxnAccount}
      item={item}
      onItemPress={() => onPressHandler(item, selectedTxnAccount, navigation)}
    />
  );
}

export function footerComponent(isFetchingNextPage) {
  return () => {
    if (isFetchingNextPage) {
      return (
        <ActivityIndicator
          testID="activityIndicator"
          color={colors.secondary.orchid}
          size="small"
          style={appStyles.paddingBottom(10)}
        />
      );
    }
    return null;
  };
}

export const listEmpty = t => (
  <View style={appStyles.marginTop(42)}>
    <View style={styles.imageContainer}>
      <Image source={generalEmptyState} style={styles.imageStyle} contentFit="contain" />
    </View>
    <Text variant="md-bold" alignSelf="center" color={colors.neutral.darkMist}>
      {t('transactionHistory.belum')}
    </Text>
    <Text variant="sm-normal" textAlign="center" marginTop="3px" color={colors.neutral.darkMist}>
      {t('transactionHistory.belumDes')}
    </Text>
  </View>
);

export const itemSeperatorComponent = () => null;

export function onTransactionMount(onRefresh, editRefresh, setEditRefresh) {
  return () => {
    if (editRefresh) {
      setEditRefresh(false);
      onRefresh();
    }
  };
}

const TransactionList = () => {
  const { selectedTxnAccount, selectedPCId, setInitialData, editRefresh, setEditRefresh } = getStore();
  const IS_NOT_POINTS = isNotPoints(selectedTxnAccount);
  const navigation = useNavigation();
  const { t } = useTranslation('common');
  const toast = useToast();
  const {
    isError,
    error,
    isLoading: loadingList,
    isRefetching: refresh,
    data: list = { pages: [] },
    refetch: onRefresh,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = fetchTransactionHistory({ accountNumber: selectedTxnAccount?.id ?? 0, paymentCycleId: selectedPCId });

  useEffect(() => {
    showErrorOnAPI(isError, error, toast, t, navigation, setInitialData);
  }, [isError, error]);

  useEffect(onTransactionMount(onRefresh, editRefresh, setEditRefresh), [onRefresh, editRefresh]);

  const footer = useCallback(footerComponent(isFetchingNextPage), [isFetchingNextPage]);

  const loadMore = () => {
    if (hasNextPage) fetchNextPage();
  };

  const onEndReached = () => {
    if (!isFetchingNextPage) loadMore();
  };

  const renderItem = renderItemHandler(IS_NOT_POINTS, selectedTxnAccount, navigation);

  return shouldShowFlatListOrLoader({
    loadingList,
    listEmpty: listEmpty(t),
    refresh,
    onRefresh,
    transactionList: list.pages.flatMap(page => page),
    renderItem,
    onEndReached,
    footer,
    itemSeperatorComponent,
  });
};

export default TransactionList;
