import { EARNED_REFERRAL, REFERRAL_TNC } from '@api/queries/referral';
import { useLazyQuery } from '@apollo/client';
import ArrowDown from '@atoms/ArrowDown';
import ArrowUp from '@atoms/ArrowUp';
import InfoTextComponent from '@atoms/InfoTextComponent';
import ReferralCode from '@atoms/ReferralCode';
import ReferralInfoTxCard from '@atoms/ReferralInfoTxCard';
import ReferralInfoUserCard from '@atoms/ReferralInfoUserCard';
import ReferralTnC from '@atoms/ReferralTnC';
import ReferralTrail from '@atoms/ReferralTrail';
import TextCurrency from '@atoms/TextCurrency';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import { useRoute } from '@react-navigation/native';
import appStyles from '@root/appStyles';
import { getStore } from '@store/storeUtils';
import colors from '@theme/colors';
import AmplitudeHelper from '@utils/analytics';
import { AMPLITUDE_CONSTANTS } from '@utils/analytics/constants';
import { scale } from '@utils/normalize';
import { SVG_ICONS } from '@utils/SvgIcons';
import { getReferralEncryption } from '@utils/utils';
import { View, Text, Divider, Button, useToast } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaView, TouchableOpacity, ScrollView, Platform } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { onEarnedReferralComplete, referralPageHandlers } from './referralPageUtils';
import styles from './styles';

function shouldShowUpOrDownArrow(collapsedUsage) {
  return collapsedUsage ? <ArrowUp /> : <ArrowDown />;
}

function shouldRenderReferralTnC(referralData, referrerAmt, refereeAmt) {
  return referralData?.referralTnc?.tnc?.map((tncItem, index) => (
    <ReferralTnC key={tncItem} tncItem={tncItem} index={index} referrerAmt={referrerAmt} refereeAmt={refereeAmt} />
  ));
}

function GGReferralPage() {
  const { t } = useTranslation('common');
  const { employeeId, employerId, referralShortLink } = getStore();
  const referralCode = getReferralEncryption(employeeId?.toString());
  const [collapsedUsage, setCollapsedUsage] = useState(false);

  const [referralData, setReferralData] = useState(null);
  const [earnedPoints, setEarnedPoints] = useState(0);
  const toast = useToast();
  const [getEarnedReferral, { loading }] = useLazyQuery(EARNED_REFERRAL, {
    fetchPolicy: 'no-cache',
    variables: { empId: employeeId },
    onCompleted: onEarnedReferralComplete(setEarnedPoints),
    // onError: error => {},
  });
  const [
    getReferralRulesTNC,
    // , { error: errorRule }
  ] = useLazyQuery(REFERRAL_TNC, {
    fetchPolicy: 'no-cache',
    variables: { employerId },
    onCompleted: data => {
      setReferralData(data?.referral_config[0]);
    },
  });

  useEffect(() => {
    getReferralRulesTNC();
    getEarnedReferral();
  }, []);

  useEffect(() => {
    // setLoading(loading);
  }, [loading]);

  const route = useRoute();

  useEffect(() => {
    AmplitudeHelper.logEvent(AMPLITUDE_CONSTANTS.Referral.TNC.name, {
      [AMPLITUDE_CONSTANTS.Referral.TNC.attributes.source]: route?.params?.source ?? null,
      [AMPLITUDE_CONSTANTS.Referral.TNC.attributes.referral_code]: referralCode || null,
    });
  }, []);

  const { shareShortLinkCallBack, onPressDropDown, onCopyToClipBoard } = referralPageHandlers(
    setCollapsedUsage,
    collapsedUsage,
    referralCode,
    toast,
    t,
    referralData,
  );

  const share = useCallback(shareShortLinkCallBack, [referralCode, referralData]);

  const onShareHandler = () => share(referralShortLink);
  const onCopyToClipBoardHandler = () => onCopyToClipBoard(referralShortLink);

  return (
    <SafeAreaView style={appStyles.safeAreaView}>
      <ScrollView>
        <View style={[appStyles.marginHorizontal(16), appStyles.marginVertical(16)]}>
          <Text variant="md-bold">{t('referral.txtInviteFriends')}</Text>
          <View
            style={[
              appStyles.borderWithShadow,
              appStyles.marginHorizontal(0),
              appStyles.paddingVertical(12),
              appStyles.paddingHorizontal(0),
            ]}>
            <Text variant="lg-normal" alignSelf="center">
              {t('referral.txtCollectPrize')}
            </Text>
            <View flexDirection="row" alignItems="center" justifyContent="center" style={appStyles.marginTop(4)}>
              <View style={{}}>{SVG_ICONS.coin}</View>
              <TextCurrency
                showCurrencySymbol={false}
                amountCurrency={2500000}
                variant="xl-bold"
                color={colors.warning.darkSunflower}
                style={appStyles.marginLeft(6)}
                suffix={t('referral.txtPoin')}
              />
            </View>
            <Divider style={appStyles.marginVertical(12)} />
            <View flexDirection="row" alignItems="center">
              <Text color={colors.neutral.darkMist} style={appStyles.marginLeft(16)} variant="sm-normal">
                {t('referral.txtCollectedText')}
              </Text>
              <View style={appStyles.marginLeft(4)}>{SVG_ICONS.coin}</View>
              <TextCurrency
                showCurrencySymbol={false}
                amountCurrency={earnedPoints}
                variant="md-bold"
                color={colors.neutral.charcoal}
                style={appStyles.marginLeft(6)}
              />
            </View>
          </View>
          <InfoTextComponent
            iconContainer={styles.infoAdj}
            iconName="info-outline"
            infoText={t('referral.textPoinNote')}
            customTextStyle={styles.infoTextContainer}
            size={scale(24)}
            containerStyle={styles.containerStyle}
            iconColor={colors.secondary.orchid}
            variant="xsm-bold"
            show
          />
          <ReferralCode referralCode={referralCode} />
          <ReferralTrail />
          <View flexDirection="row" style={appStyles.marginTop(16)}>
            <ReferralInfoUserCard amount={referralData?.referrerRewardAmount} />
            <ReferralInfoTxCard amount={referralData?.refereeRewardAmount} />
          </View>
        </View>
        <Divider />
        <TouchableOpacity testID="btnCollapseExpand" onPress={onPressDropDown} style={styles.btnCollapseExpand}>
          <Text flex={1} variant="md-bold">
            {t('referral.txtReferralTnCHeader')}
          </Text>
          {shouldShowUpOrDownArrow(collapsedUsage)}
        </TouchableOpacity>
        <Collapsible collapsed={!collapsedUsage}>
          <View backgroundColor={colors.neutral.mist} style={styles.collapsibleContainer}>
            {shouldRenderReferralTnC(
              referralData,
              referralData?.referrerRewardAmount,
              referralData?.refereeRewardAmount,
            )}
          </View>
        </Collapsible>
        <Divider />
      </ScrollView>
      <View
        style={[
          appStyles.borderWithShadow,
          appStyles.marginHorizontal(0),
          appStyles.paddingHorizontal(16),
          appStyles.flexRow,
          appStyles.paddingVertical(12),
          appStyles.borderBottomLeftRadius(0),
          appStyles.borderBottomRightRadius(0),
        ]}>
        <Button
          variant="ghost"
          testID="btnCopyReferral"
          onPress={onCopyToClipBoardHandler}
          flex={1}
          style={appStyles.marginRight(8)}
          leftIcon={<MaterialIcons name="content-copy" size={24} color={colors.primary.carnation} />}>
          {t('referral.copyButtonText')}
        </Button>
        {Platform.OS !== 'web' ? (
          <Button
            testID="btnShareLink"
            onPress={onShareHandler}
            leftIcon={<Ionicons name="share-social" size={24} color={colors.neutral.cotton} />}
            flex={1}>
            {t('referral.shareButtonText')}
          </Button>
        ) : null}
      </View>
    </SafeAreaView>
  );
}

GGReferralPage.propTypes = {};
GGReferralPage.defaultProps = {};
export default React.memo(GGReferralPage);
