import { MaterialIcons } from '@expo/vector-icons';
import { scale } from '@utils/normalize.js';
import { Text } from 'native-base';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Pressable } from 'react-native';
import Collapsible from 'react-native-collapsible';
import styles from './style';
import Item from '../Item/index';

const FAQSection = ({ title, data, collapse, handleCollapse, containerStyle }) => {
  const { t } = useTranslation('common');
  return (
    <>
      <Pressable testID="title" style={[styles.container, containerStyle]} onPress={handleCollapse}>
        <Text variant="md-bold">{title}</Text>

        <View style={styles.iconContainerStyle}>
          <MaterialIcons name={collapse ? 'keyboard-arrow-down' : 'keyboard-arrow-up'} size={scale(24)} />
        </View>
      </Pressable>
      <Collapsible collapsed={collapse} style={styles.collapseContainerStyle}>
        {data.map(e => (
          <Item key={e.desc} icon={e.icon} title={e.desc} t={t} />
        ))}
      </Collapsible>
    </>
  );
};

FAQSection.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      desc: PropTypes.string.isRequired,
    }),
  ).isRequired,
  collapse: PropTypes.bool.isRequired,
  handleCollapse: PropTypes.func.isRequired,
  containerStyle: PropTypes.shape({}),
};

FAQSection.defaultProps = {
  containerStyle: {},
};
export default FAQSection;
